import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import parse from 'html-react-parser';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  p: 4,
  borderRadius:3,
  overflow:'scroll',
  minHeight:200,
  maxHeight:500,
  padding:5,
};

export default function BasicModal({title,description}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button onClick={handleOpen} style={{color:'yellow', fontSize:'1rem', textDecoration:'underline'}}>READ MORE</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
           <span style={{fontSize:"2rem", fontWeight:"bold"}}>{title}</span>
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 1 }}>
           <span style={{fontSize:"1.5rem"}}>{parse(`<p>
</p>`)}{description}</span>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
